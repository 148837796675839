<template>
  <div>
    <a-card title="个人中心">
      <a-form-model :model="userInfo"
                    :label-col="{span: 6}"
                    :wrapper-col="{span: 18}">
        <a-form-model-item label="姓名"
                           prop="name">
          <span>{{userInfo.name}}</span>
          <a-button style="padding:0;margin-left:30px;" type="link" @click="handlerShowUpdateName">修改姓名</a-button>
        </a-form-model-item>

        <a-form-model-item label="登录账号">
          <span>{{userInfo.phone}}</span>
          <a-button style="padding:0;margin-left:30px;" type="link" @click="handlerShowUpdatePwd">修改密码</a-button>
        </a-form-model-item>

        <a-form-model-item v-if="userInfo.outlets_name" label="归属网点"
                           prop="name">
          {{userInfo.outlets_name}}
        </a-form-model-item>

        <a-form-model-item v-if="userInfo.outlets_citys" label="城市权限"
                           prop="outlets_citys">
          {{userInfo.outlets_citys}}
        </a-form-model-item>

        <a-form-model-item v-if="userInfo.financial_accounts" label="财务账户"
                           prop="financial_accounts">
          {{userInfo.financial_accounts}}
        </a-form-model-item>

        <!-- <a-form-model-item :wrapper-col="{ span: 14, offset: 8 }">
          <a-button type="primary" @click="handlerShowUpdateName">修改姓名</a-button>
        </a-form-model-item>

        <a-form-model-item :wrapper-col="{ span: 14, offset: 8 }">
          <a-button type="primary" @click="handlerShowUpdatePwd">修改密码</a-button>
        </a-form-model-item> -->
        
      </a-form-model>
    </a-card>

    <a-modal :visible.sync="showPopPwd"
           title="修改密码"
           destroyOnClose
           width="30%"
           @ok="handlePwdOk"
           @cancel="showPopPwd = false">
      <a-form-model :model="userInfo"
                    :label-col="{span: 6}"
                    :wrapper-col="{span: 18}">
        <a-form-model-item label="输入密码"
                           prop="password">
          <a-input type="new-password" v-model="formData.password"></a-input>
        </a-form-model-item>
        <a-form-model-item label="再次输入"
                           prop="passwordSure">
          <a-input type="new-password" v-model="formData.passwordSure"></a-input>
        </a-form-model-item>
      </a-form-model>
    </a-modal>

    <a-modal :visible.sync="showPopName"
           title="修改姓名"
           destroyOnClose
           width="30%"
           @ok="handleNameOk"
           @cancel="showPopName = false">
      <a-form-model :model="userInfo"
                    :label-col="{span: 6}"
                    :wrapper-col="{span: 18}">
        <a-form-model-item label="输入姓名">
          <a-input v-model="formData.name"></a-input>
        </a-form-model-item>
      </a-form-model>
    </a-modal>

  </div>
</template>

<script>
import { getUserInfo, updateUserPassword, updateUserName } from '@/api/user.js'
export default {
  data () {
    return {
      userInfo: {},
      formData:{
        name: '',
        password: ''
      },
      showPopName: false,
      showPopPwd: false,
    }
  },
  mounted () {
    this.initData()
  },
  methods: {
    async initData () {
      const { code, data } = await getUserInfo()
      if (code === 0) {
        this.userInfo = data
      }
    },

    handlerShowUpdateName(){
      this.showPopName = true
      this.formData.name = this.userInfo.name
    },

    handlerShowUpdatePwd(){
      this.showPopPwd = true
      this.formData.name = this.userInfo.name
    },

    handlePwdOk(){
      let _this = this
      if(!this.formData.password){
        this.$message.warning('密码不能为空')
        return
      }
      if(this.formData.password != this.formData.passwordSure){
        this.$message.warning('两次密码必须相同')
        return
      }
      updateUserPassword(_this.formData).then(res => {
        if (res.code === 0) {
          _this.$message.success("修改成功！")
          _this.showPopPwd = false
        }
      })
    },
    handleNameOk(){
      let _this = this
      if(!this.formData.name){
        this.$message.warning('请输入姓名')
        return
      }
      updateUserName(_this.formData).then(res => {
        if (res.code === 0) {
          _this.$message.success("修改成功！")
          _this.showPopName = false
          _this.userInfo.name = _this.formData.name
        }
      })
    },

  }
}
</script>

<style>
</style>